import Image from 'components/Image'
import image from 'images/faq/BFT-CorporateCatering.jpg'
import React from 'react'
import { Link } from 'components/pages/FaqPage'
import ButtonLink from 'components/ui/ButtonLink'

const WhatIfMyOfficeOrBusinessAlreadyHasCatering = () => (
  <div className="prose prose-md max-w-none">
    <h1 itemProp="name">
      Why Should I Switch From My Current Catering to Corporate Food Truck
      Catering?
    </h1>
    <div className="max-w-lg">
      <Image
        alt="BFT Corporate Catering"
        className="shadow rounded-lg"
        src={image}
      />
    </div>

    <div
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
      itemScope
    >
      <div className="text-justify" itemProp="text">
        <p>
          If you already get food from banquet or trough-style catering in giant
          metal trays, then many corporate catering problems might look familiar
          to you. Let’s look at why{' '}
          <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
            corporate food truck catering
          </Link>{' '}
          is a better option.
        </p>

        <h3>The Problems With Traditional Corporate Catering</h3>
        <p>
          The problem with traditional catering is that you’re making food for
          everyone, but not everyone is going to eat it. And the rest of that
          food becomes waste that goes straight into the garbage. The best part?
          You’re paying for all that food that gets dumped.
        </p>
        <p>
          Catering dishes provide very little choice for people, especially for
          those with dietary restrictions. Typically, you’re going to have 1-2
          meat plates, 1 veggie option, maybe 1-2 sides, and that’s it. Forget
          about the flexibility of a full menu or items like burritos or
          sandwiches with custom fillings. And if someone is allergic to garlic
          or something specific, they might literally not be able to eat
          anything with this cookie-cutter approach.
        </p>
        <p>
          While catering trays might be kept hot, they certainly aren’t fresh or
          made-to-order. Those metal trays had food in them likely hours before
          you even eat it, so the chances of getting fresh, crunchy, or
          non-soggy food becomes slim to none.
        </p>
        <h3>Why Traditional Catering Falls Short</h3>
        <p>
          In other words, if you’re using traditional catering, you’re paying
          for food that gets thrown into the garbage, you’re limiting your
          options, you’re not providing choices for those with dietary
          restrictions or food allergies, you’re not allowing people to make
          their own choices about what they want to eat, and you’re having
          people settle for food premade hours before they actually eat it.
        </p>
        <h3>The Benefits of Switching to Best Food Trucks (BFT)</h3>
        <p>
          With Best Food Trucks (BFT), food is only made for people ordering, so
          there’s drastically less food waste. Instead of only one or two main
          options, people can choose from a full food truck menu of delicious
          entrees and appetizers. We have everything from coffee, dessert, and
          ice cream food trucks to hotdog and taco food trucks.
        </p>
        <p>
          If someone hates mushrooms, is allergic to garlic, or is
          vegan/gluten-free, or wants the sauce on the side or any other
          modification, they have the choice to eat what they actually want. We
          even have halal food trucks for hire.
        </p>
        <h3>Streamlined Employer-Paid Lunch Program</h3>
        <p>
          To help companies with catering switch to this drastically better
          experience with corporate food truck catering, Best Food Trucks (BFT)
          also offers a streamlined employer-paid lunch program, meaning offices
          can put a payment option on file, select which days and how much
          they’re willing to cover, and create a whitelist of people who are
          eligible.
        </p>
        <p>
          For example, an office can set eligible days to every Monday,
          Wednesday, and Friday, and offer up to $15 in credits for employees.
          Anything over $15, the employee can put a card on file and cover. This
          program applies to all the food truck rentals on our platform, so no
          need to keep running down to food trucks with a corporate card for
          every lunch shift.
        </p>
        <h3>Paying Only for What You Use</h3>
        <p>
          Now, companies are only paying for food that’s actually used, rather
          than ordering catering for everyone and having food wasted because of
          employees who called in sick, didn’t like the option, were too busy in
          a meeting, or for any other reason.
        </p>
        <p>
          Plus, we’ll give companies reports on which food trucks showed up, how
          many orders, reviews, and feedback, and other reporting to provide
          transparency in the cost of food trucks and how the program is going
          overall.
        </p>
        <h3>Upgrade Your Corporate Catering Experience</h3>
        <p>
          If you’re interested in upgrading your catering experience and making
          your employees happier and more productive, click the button below and
          we’ll set you up with a regular food truck schedule along with your
          employer-paid lunch program. Give us a call to book or learn more
          about our food truck prices and other related information.
        </p>
        <h3>
          Why Corporate Food Truck Catering Is the Future of Office Meals?
        </h3>
        <p>
          Food truck catering offers a flexibility and variety that traditional
          catering can’t match. Employees today expect more control over their
          food choices, including healthier, diet-friendly options like
          gluten-free, vegan, and halal dishes. With food trucks, every meal is
          made fresh to order, ensuring that employees receive meals that are
          tailored to their preferences, rather than generic options.
        </p>
        <p>
          Additionally, food truck catering eliminates the need for large
          amounts of food that go to waste, making it a more sustainable option
          for companies that care about reducing their environmental impact.
        </p>
        <h3>How the Employer-Paid Lunch Program Saves Companies Money?</h3>
        <p>
          One of the standout features of BFT’s catering solution is the
          employer-paid lunch program. Unlike traditional catering, where
          companies often pay for food that goes uneaten, BFT allows companies
          to only pay for food that’s actually ordered and consumed. This
          drastically reduces waste and ensures that every dollar spent is used
          efficiently. The flexible credit system allows companies to set limits
          and only pay for specific days, making it easier to manage budgets
          without sacrificing employee satisfaction.
        </p>
        <h3>Employee Satisfaction and Corporate Food Truck Catering</h3>
        <p>
          Switching to food truck catering doesn’t just save money—it boosts
          employee morale and productivity. When employees know they have access
          to fresh, made-to-order meals, they’re more likely to look forward to
          lunch breaks, leading to increased satisfaction. The ability to order
          exactly what they want, without the limitations of traditional
          catering, leads to happier, more engaged employees.
        </p>
        <p>
          By offering{' '}
          <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
            food truck catering
          </Link>
          , companies can enhance the lunch hour experience, reduce wasted time
          standing in lines, and provide a greater sense of community as
          employees enjoy more diverse and personalized meals.
        </p>

        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    </div>
  </div>
)

export default WhatIfMyOfficeOrBusinessAlreadyHasCatering
