import React from 'react'
import cn from 'classnames'

import { useHeaderConfig } from '../../HeaderProvider'
import MenuItem from '../common/MenuItem'
import CartComponent from '../../CartComponent'

import CloseMobileMenuButton from './CloseMobileMenuButton'
import OpenMobileMenuButton from './OpenMobileMenuButton'

const MainMobileMenu = ({
  isOpenMenu,
  openMenu,
  closeMenu,
  hideHeader,
  hideNavigation,
  highlightCartItems
}: {
  isOpenMenu: boolean
  openMenu: () => void
  closeMenu: () => void
  hideHeader: boolean
  hideNavigation: boolean
  highlightCartItems: boolean
}) => {
  const { logoUrl, LocalLink } = useHeaderConfig()

  return (
    <div
      className={cn(
        'flex fixed w-full lg:hidden justify-between items-center py-3 px-2 border-gray-100 shadow bg-white transition-all duration-500 ease-in-out translate-y-0 z-30',
        {
          '-translate-y-full': hideHeader
        }
      )}
    >
      <div className="border-r border-solid border-gray-300 flex-none px-2">
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a href="/">
          <img alt="BFT logo" className="h-8 w-14" src={logoUrl} />
        </a>
      </div>
      {!hideNavigation && (
        <>
          <div className="border-r border-solid border-gray-300 px-2 flex items-center justify-center flex-grow">
            <LocalLink href="/food-trucks-near-me">
              <MenuItem
                href="/food-trucks-near-me"
                title="Trucks nearby"
                titleClassName="text-xs xs:text-base"
              />
            </LocalLink>
          </div>
        </>
      )}
      <div className="border-r border-solid border-gray-300 flex-grow flex justify-center px-2">
        <CartComponent highlightCartItems={highlightCartItems} />
      </div>
      <div className="flex-none px-2">
        {isOpenMenu ? (
          <CloseMobileMenuButton onClick={closeMenu} />
        ) : (
          <OpenMobileMenuButton onClick={openMenu} />
        )}
      </div>
    </div>
  )
}

export default MainMobileMenu
