import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import times from 'lodash/times'

let algoliaClient: SearchClient

const makeSearchClient = ({
  alwaysSearch
}: {
  alwaysSearch: boolean
}): SearchClient => {
  algoliaClient =
    algoliaClient ||
    algoliasearch('QEQN0PZGGB', 'f3ff57b15a6bad6a0630d235834cf17c')

  return {
    search(requests) {
      let shouldSearch
      if (alwaysSearch) {
        shouldSearch = true
      } else {
        shouldSearch = requests.some(({ query }) => query !== '')
      }
      if (shouldSearch) {
        return algoliaClient.search(requests)
      }
      const emptyResult = {
        hits: [],
        page: 0,
        nbHits: 0,
        nbPages: 0,
        hitsPerPage: 0,
        processingTimeMS: 0,
        exhaustiveNbHits: false,
        query: '',
        params: ''
      }
      return Promise.resolve({
        results: times(requests.length, () => emptyResult)
      })
    },
    searchForFacetValues: algoliaClient.searchForFacetValues,
    appId: algoliaClient.appId,
    transporter: algoliaClient.transporter,
    addAlgoliaAgent: algoliaClient.addAlgoliaAgent,
    clearCache: algoliaClient.clearCache,
    initIndex: algoliaClient.initIndex
  }
}

export default makeSearchClient
