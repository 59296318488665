/* eslint-disable no-underscore-dangle */

import makeSearchClient from 'lib/search/makeSearchClient'
import { useEffect, useMemo, useState } from 'react'
import {
  MultipleQueriesQuery,
  RankingInfo,
  SearchResponse
} from '@algolia/client-search'
import sortBy from 'lodash/sortBy'
import debounce from 'lodash/debounce'
import pathFromUrl from 'utils/pathFromUrl'

export type LotHit = {
  objectID: string
  full_address: string
  image_medium: string
  locations: number
  locations_rounded: number
  market: string | null
  name: string
  slug: string
  type: 'LotGroup' | 'Lot'
  url: string
  path: string
  _geoloc: {
    lat: number
    lng: number
  }
  _rankingInfo: RankingInfo
}

export type TruckHit = {
  objectID: string
  average_rating: number
  average_rating_rounded: number
  food_types: string[]
  food_types_string: string
  image_medium: string
  locations: number
  locations_rounded: number
  market: string
  name: string
  orders: number
  orders_rounded: number
  restaurant: string
  type: 'Truck'
  url: string
  path: string
  _geoloc: {
    lat: number
    lng: number
  }
  _rankingInfo: RankingInfo
}

export type MarketHit = {
  objectID: string
  name: string
  full_address: string
  image_medium: string
  locations_rounded: number
  type: 'Market'
  url: string
  path: string
  _geoloc: {
    lat: number
    lng: number
  }
  _rankingInfo: RankingInfo
}

type SearchHit = LotHit | TruckHit | MarketHit

type Args = {
  alwaysSearch: boolean
  maxHits: number
  maxMarketHits: number
  debounceTime?: number
  initialQuery?: string
}

type Result = {
  query: string
  setQuery: (newQuery: string) => void
  hits: SearchHit[]
  lotHits: LotHit[]
  truckHits: TruckHit[]
  marketHits: MarketHit[]
  isLoading: boolean
  showEmptyResult: boolean
}

const INDEX_PREFIX =
  process.env.NODE_ENV === 'production' ? 'production' : 'development'

// currently there's no data in dev index
// const INDEX_PREFIX = 'production'

const TRUCKS_INDEX_NAME = `${INDEX_PREFIX}_trucks`
const LOTS_INDEX_NAME = `${INDEX_PREFIX}_lots`
const MARKETS_INDEX_NAME = `${INDEX_PREFIX}_markets`

export default function useSearch({
  alwaysSearch,
  maxHits,
  maxMarketHits,
  debounceTime = 200,
  initialQuery = ''
}: Args): Result {
  const client = useMemo(
    () =>
      typeof window !== 'undefined' ? makeSearchClient({ alwaysSearch }) : null,
    [alwaysSearch]
  )

  const [query, setQuery] = useState<string>(initialQuery)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [showEmptyResult, setShowEmptyResult] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [[marketHits, lotHits, truckHits], setLotTruckHits] = useState<
    [MarketHit[], LotHit[], TruckHit[]]
  >([[], [], []])
  const [searchHits, setSearchHits] = useState<SearchHit[]>([])
  const debouncedSearch = useMemo(() => {
    if (!client) return null

    const search = (queries: MultipleQueriesQuery[]) => {
      setIsLoading(true)
      client
        .search(queries, {
          strategy: 'stopIfEnoughMatches',
          cacheable: true
        })
        .then(({ results }) => {
          const truckResults = results[0] as SearchResponse<TruckHit>
          const lotResults = results[1] as SearchResponse<LotHit>
          const marketResults = results[2] as SearchResponse<MarketHit>
          const parsedTruckHits = truckResults.hits.map((r) => ({
            ...r,
            path: pathFromUrl(r.url)
          }))
          const parsedLotHits = lotResults.hits.map((r) => ({
            ...r,
            path: pathFromUrl(r.url)
          }))
          setLotTruckHits([marketResults.hits, parsedLotHits, parsedTruckHits])
          setShowEmptyResult(
            parsedLotHits.length === 0 &&
              parsedTruckHits.length === 0 &&
              marketResults.length === 0 &&
              !isFirstRender
          )
          setSearchHits(
            sortBy(
              [...marketResults.hits, ...parsedTruckHits, ...parsedLotHits],
              (h) => {
                // prioritize markets
                if (h.type === 'Market') {
                  return -h._rankingInfo.userScore * 3
                }
                // prioritize trucks over places
                if (h.type === 'Truck') {
                  return -h._rankingInfo.userScore * 2
                }
                return -h._rankingInfo.userScore
              }
            )
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (isFirstRender) return search
    return debounce(search, debounceTime)
  }, [client, isFirstRender, debounceTime])

  useEffect(() => {
    if (isFirstRender) setIsFirstRender(false)
  }, [isFirstRender])

  useEffect(() => {
    const queries: MultipleQueriesQuery[] = [
      {
        indexName: TRUCKS_INDEX_NAME,
        query,
        params: {
          hitsPerPage: maxHits,
          aroundLatLngViaIP: true,
          getRankingInfo: true,
          aroundRadius: 'all'
        }
      },
      {
        indexName: LOTS_INDEX_NAME,
        query,
        params: {
          hitsPerPage: maxHits,
          aroundLatLngViaIP: true,
          getRankingInfo: true,
          aroundRadius: 'all'
        }
      },
      {
        indexName: MARKETS_INDEX_NAME,
        query,
        params: {
          hitsPerPage: maxMarketHits,
          aroundLatLngViaIP: true,
          getRankingInfo: true,
          aroundRadius:
            process.env.NODE_ENV === 'production' ? 1000 * 100 : 'all'
        }
      }
    ]
    if (!debouncedSearch) return

    debouncedSearch(queries)
  }, [debouncedSearch, query, maxHits, maxMarketHits])

  return {
    query,
    setQuery,
    hits: searchHits,
    isLoading,
    lotHits,
    marketHits,
    truckHits,
    showEmptyResult
  }
}
