import React from 'react'
import PageMeta from 'components/PageMeta'
import PageLayout from 'components/pages/PageLayout'
import { withApollo } from 'components/withApollo'
import { GetStaticPathsResult, NextPage } from 'next'
import { useRouter } from 'next/router'
import faq from 'utils/faq'
import NotFoundPage from 'components/pages/NotFoundPage/NotFoundPage'
import getStaticApolloProps from 'lib/getStaticApolloProps'

export const Link = ({
  href,
  children
}: {
  href: string
  children: React.ReactNode
}) => (
  <a className="text-orange-700" href={href}>
    {children}
  </a>
)

function FaqPage() {
  const router = useRouter()
  const { slug, categoryCode } = router.query

  const question = faq.find((q) => q.slug === slug)

  if (!question) return <NotFoundPage />

  const relatedQuestions = faq.filter(
    (q) => q.categoryCode === categoryCode && q.title !== question.title
  )

  return (
    <>
      <PageMeta
        canonicalPath={`/faq/${categoryCode}/${slug}`}
        description="FAQ about best food trucks"
        title={`Best Food Trucks | FAQ - ${question.title}`}
      />
      <PageLayout staticPage>
        <div
          className="w-full lg:py-16 py-8 px-4"
          itemType="https://schema.org/FAQPage"
          itemScope
        >
          <div
            className="prose max-w-none"
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
            itemScope
          >
            <div className="grid-cols-3 lg:grid gap-16 lg:divide-x lg:divide-y-0 divide-y divide-solid divide-gray-100">
              <div className="col-span-2">
                <div className="col-span-2">
                  <question.component />
                </div>
              </div>

              {relatedQuestions.length > 0 && (
                <div className="col-span-1 prose prose-md lg:pl-10 lg:pt-0 lg:mt-0 pt-10 mt-10">
                  <h2 className="text-gray-700">Related Questions</h2>
                  <div className="flex flex-col gap-y-4">
                    {relatedQuestions.map((q) => (
                      <div key={q.slug}>
                        <a
                          className="underline text-gray-700 hover:text-orange-700 transition-all duration-200"
                          href={`/faq/${q.categoryCode}/${q.slug}`}
                        >
                          <span className="font-normal">{q.title}</span>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export const getStaticProps = getStaticApolloProps(FaqPage)

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  const paths = faq.map((q) => `/faq/${q.categoryCode}/${q.slug}`)
  return {
    paths,
    fallback: 'blocking'
  }
}

export default withApollo({ ssr: false })(FaqPage as NextPage)
