import React, { useState } from 'react'
import cn from 'classnames'
import { TruckIcon, SearchIcon } from '@heroicons/react/solid'

import MenuItem from './components/common/MenuItem'
import MenuTransition from './components/desktop/MenuTransition'
import SubMenuItemsCustomers from './components/common/SubMenuItemsCustomers'
import SubMenuItemsTrucks from './components/common/SubMenuItemsTrucks'
import SubMenuItemsProfile from './components/common/SubMenuItemsProfile'
import { useScrollPosition } from './useScrollPosition'
import CartComponent from './CartComponent'
import { useHeaderConfig } from './HeaderProvider'

type HeaderContainerProps = {
  children: JSX.Element | JSX.Element[]
  narrowHeader: boolean
  transparentHeader: boolean
}
const HeaderContainer = ({
  children,
  narrowHeader,
  transparentHeader
}: HeaderContainerProps) => {
  return (
    <div
      className={cn(
        'fixed w-full hidden lg:block z-30 transition-all duration-200 ease-in-out border-b border-gray-200 border-opacity-25',
        {
          'h-20': !narrowHeader,
          'h-16': narrowHeader,
          'bg-white shadow': !transparentHeader,
          'bg-opacity-0 text-white border border-1 border-solid border-white': transparentHeader
        }
      )}
    >
      <div
        className={cn(
          'flex justify-between items-center max-w-7xl mx-auto px-4 py-4',
          {
            'h-20': !narrowHeader,
            'h-16': narrowHeader
          }
        )}
      >
        {children}
      </div>
    </div>
  )
}

type Props = {
  transparentOnTop: boolean
  highlightCartItems: boolean
  hideNavigation: boolean
}

export default function DesktopHeader({
  transparentOnTop = false,
  hideNavigation,
  highlightCartItems
}: Props) {
  const { logoUrl, user, LocalLink } = useHeaderConfig()
  const [transparentHeader, setTransparentHeader] = useState<boolean>(
    transparentOnTop
  )
  const [narrowHeader, setNarrowHeader] = useState<boolean>(false)

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y === 0) {
        setTransparentHeader(transparentOnTop)
        setNarrowHeader(false)
      } else {
        setNarrowHeader(true)
        setTransparentHeader(false)
      }
    },
    [transparentHeader, narrowHeader]
  )

  return (
    <HeaderContainer
      narrowHeader={narrowHeader}
      transparentHeader={transparentHeader}
    >
      <>
        {!hideNavigation && (
          <>
            <div>
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              <a className="flex-none" href="/">
                <img
                  alt="bft logo"
                  className="h-12 w-20 lg:h-16 lg:w-28 mb-2"
                  src={logoUrl}
                />
              </a>
            </div>

            <nav className="flex items-center mx-auto">
              <LocalLink href="/food-trucks-near-me">
                <MenuItem
                  href="/food-trucks-near-me"
                  Icon={TruckIcon}
                  title="Trucks near me"
                  transparentHeader={transparentHeader}
                />
              </LocalLink>
              <LocalLink href="/food-trucks-search">
                <MenuItem
                  href="/food-trucks-search"
                  Icon={SearchIcon}
                  title="Search"
                  transparentHeader={transparentHeader}
                />
              </LocalLink>
              <MenuTransition
                title="Customers"
                transparentHeader={transparentHeader}
              >
                <SubMenuItemsCustomers />
              </MenuTransition>

              <MenuTransition
                title="Truck owners"
                transparentHeader={transparentHeader}
              >
                <SubMenuItemsTrucks />
              </MenuTransition>
            </nav>
          </>
        )}

        <div
          className={cn('flex items-center justify-end md:ml-10', {
            'flex-1': hideNavigation
          })}
        >
          {user ? (
            <MenuTransition
              title="My Profile"
              transparentHeader={transparentHeader}
            >
              <SubMenuItemsProfile user={user} />
            </MenuTransition>
          ) : (
            <MenuItem
              href="/customers/login"
              title="Login / Sign up"
              transparentHeader={transparentHeader}
            />
          )}
          <CartComponent highlightCartItems={highlightCartItems} />
        </div>
      </>
    </HeaderContainer>
  )
}
