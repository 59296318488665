import { useQuery, gql } from '@apollo/client'
import {
  currentCartQuery,
  currentCartQuery_currentCart as currentCartQueryCurrentCart
} from 'queries/types/currentCartQuery'

const menuItemCartFragment = gql`
  fragment menuItemCartFragment on CartItem {
    id
    quantity
    locationItem {
      id
    }
  }
`

const cartCurrentLocationFragment = gql`
  fragment cartCurrentLocationFragment on Cart {
    id
    location {
      id
      workStatus
      truck {
        id
        name
      }
    }
  }
`

const QUERY = gql`
  ${menuItemCartFragment}
  ${cartCurrentLocationFragment}
  query currentCartQuery {
    currentCart {
      id
      uuid
      price {
        subtotal {
          formatted
          cents
        }
      }
      items {
        ...menuItemCartFragment
      }
      ...cartCurrentLocationFragment
    }
  }
`

type Result = {
  loading: boolean
  cart: currentCartQueryCurrentCart | null
  refetch: () => void
}

type Args = {
  skip: boolean
}

export default function useCart({ skip }: Args = { skip: false }): Result {
  const { loading, data, refetch } = useQuery<currentCartQuery>(QUERY, {
    fetchPolicy: 'cache-first',
    skip
  })
  return {
    loading,
    cart: data?.currentCart || null,
    refetch
  }
}
