import React, { useContext } from 'react'
import Link, { LinkProps } from 'next/link'
import { buttonClass, ButtonStyle } from 'components/ui/Button'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

export type ButtonLinkProps = {
  children:
    | React.ReactElement
    | string
    | null
    | (React.ReactElement | string | number | null)[]
  disabled?: boolean
  target?: '_blank' | '_self'
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void
  role?: string
} & ButtonStyle &
  React.PropsWithChildren<LinkProps>

const ButtonLink = ({
  children,
  fullWidth,
  size = 'normal',
  variant,
  color,
  shadow,
  target = '_self',
  className,
  onClick,
  onKeyDown,
  role = 'button',
  ...linkProps
}: ButtonLinkProps) => {
  const { albertsons } = useContext(ShiftPageContext)

  return (
    // eslint-disable-next-line react/button-has-type
    <Link
      {...linkProps}
      className={buttonClass({
        color,
        fullWidth,
        size,
        variant,
        shadow,
        className,
        albertsons
      })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={role}
      target={target}
    >
      {children}
    </Link>
  )
}

export default ButtonLink
