import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'
import isArray from 'lodash/isArray'

type Coordinates = [number, number]

type Props = {
  title?: string
  description?: string
  path?: string
  url?: string
  image?: string
  keywords?: string
  coordinates?: Coordinates | null
  canonicalPath?: string
  noIndex?: boolean
  ldJson?: string[] | string | null
} & (
  | {
      canonicalPath: string
    }
  | {
      noIndex: true
    }
)

export default function PageMeta({
  title = 'Best Food Trucks (BFT)',
  description = 'Find & Book Food Trucks for Catering, Events, Weddings, and Office Lunches',
  url,
  path,
  image,
  keywords,
  coordinates,
  canonicalPath,
  noIndex = false,
  ldJson = []
}: Props) {
  const router = useRouter()
  const pageUrl =
    url || `https://www.bestfoodtrucks.com${path || router.asPath}`
  const canonicalUrl = canonicalPath
    ? `https://www.bestfoodtrucks.com${canonicalPath}`
    : null
  return (
    <Head>
      {title && (
        <>
          <title key="title">{title}</title>
          <meta key="title-meta" content={title} name="title" />
        </>
      )}
      {description && (
        <meta key="description" content={description} name="description" />
      )}
      {keywords && <meta key="keywords" content={keywords} name="keywords" />}
      {noIndex && <meta content="noindex" name="robots" />}
      {coordinates && (
        <meta
          key="geo.position"
          content={coordinates.join('; ')}
          name="geo.position"
        />
      )}

      {/* Open Graph / Facebook */}
      <meta key="og:type" content="website" property="og:type" />
      <meta key="og:url" content={pageUrl} property="og:url" />
      {title && <meta key="og:title" content={title} property="og:title" />}
      {description && (
        <meta
          key="og:description"
          content={description}
          property="og:description"
        />
      )}
      {image && <meta key="og:image" content={image} property="og:image" />}

      {/* Twitter */}
      <meta key="twitter:url" content={pageUrl} property="twitter:url" />
      {title && (
        <meta key="twitter:title" content={title} property="twitter:title" />
      )}
      {description && (
        <meta
          key="twitter:description"
          content={description}
          property="twitter:description"
        />
      )}
      {image && (
        <meta key="twitter:image" content={image} property="twitter:image" />
      )}
      {canonicalUrl && (
        <link key="canonical-url" href={canonicalUrl} rel="canonical" />
      )}
      {ldJson &&
        (isArray(ldJson) ? ldJson : [ldJson])
          .filter((v) => v && v.length)
          .map((j, index) => (
            <script
              // eslint-disable-next-line react/no-array-index-key
              key={`ld_json_${index}_${j.slice(0, 40)}`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: j
              }}
              type="application/ld+json"
            />
          ))}
    </Head>
  )
}
