import React from 'react'
import useSearch from 'lib/search/useSearch'
import SearchResults from 'components/Search/SearchResults'
import { SearchIcon } from '@heroicons/react/outline'
import SpinnerIcon from 'components/icons/SpinnerIcon'

type Props = {
  initialQuery?: string
  header: string
}
const SearchWidget = ({ initialQuery = '', header }: Props) => {
  const {
    query,
    setQuery,
    lotHits,
    marketHits,
    truckHits,
    isLoading,
    showEmptyResult
  } = useSearch({
    alwaysSearch: false,
    maxHits: 8,
    maxMarketHits: 2,
    initialQuery
  })
  return (
    <div className="w-full mt-10 max-w-screen-lg mx-auto">
      <div className="px-4">
        <h1 className="text-xl mb-4 font-bold text-gray-700 text-center">
          {header}
        </h1>

        <div className="w-full flex p-2 bg-white cursor-pointer h-20 items-center top-0 shadow-sm rounded-md border border-solid border-gray-200">
          <div className="md:pl-2 md:pr-4 pr-2">
            {isLoading ? (
              <SpinnerIcon className="animate-spin text-orange-300 w-8 h-8" />
            ) : (
              <SearchIcon className="text-orange-300 w-8 h-8" />
            )}
          </div>
          <input
            className="bg-white outline-none-mouse w-full leading-10 md:text-2xl placeholder-gray-400"
            name="searchQuery"
            onChange={(e) => setQuery(e.target.value)}
            placeholder="e.g. Taco truck in Los Angeles"
            value={query}
          />
        </div>
      </div>
      <div className="mb-10">
        <SearchResults
          lotHits={lotHits}
          marketHits={marketHits}
          showEmptyResult={showEmptyResult}
          truckHits={truckHits}
        />
      </div>
    </div>
  )
}

export default SearchWidget
