import { useQuery } from '@apollo/client'
import {
  currentCustomerQuery,
  currentCustomerQuery_currentCustomer as currentCustomerQueryCurrentCustomer
} from 'queries/types/currentCustomerQuery'
import { useEffect } from 'react'
import { setReportingUser } from 'utils/rollbar'

import { chatLogin, chatLogout } from '../utils/chatBox'

import { QUERY } from './getCurrentUser'

export type User = currentCustomerQueryCurrentCustomer

type Result = {
  loading: boolean
  user: User | null
  refetch: () => void
}

type Args = {
  skip?: boolean
}

export default function useUser(
  { skip = false }: Args = { skip: false }
): Result {
  const { loading, data, refetch } = useQuery<currentCustomerQuery>(QUERY, {
    fetchPolicy: 'cache-first',
    skip
  })
  const user = data?.currentCustomer || null

  useEffect(() => {
    if (typeof window === 'undefined') {
      setReportingUser(user)
    }

    if (user) {
      chatLogin({ user })
    } else {
      chatLogout()
    }
  }, [user])

  return {
    loading,
    user,
    refetch
  }
}
