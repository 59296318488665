import React, { useState } from 'react'
import {
  MapIcon,
  SearchIcon,
  TruckIcon,
  UserIcon
} from '@heroicons/react/solid'
import {
  LogoutIcon,
  QuestionMarkCircleIcon as QuestionIcon,
  ArrowCircleRightIcon,
  NewspaperIcon as OrdersIcon
} from '@heroicons/react/outline'

import { useHeaderShow } from './HeaderShow'
import SubMenuItemsTrucks from './components/common/SubMenuItemsTrucks'
import SubMenuItemsCustomers from './components/common/SubMenuItemsCustomers'
import MobileMenu from './components/mobile/MobileMenu'
import SubMenuItem from './components/common/SubMenuItem'
import MainMobileMenu from './components/mobile/MainMobileMenu'
import { useScrollPosition } from './useScrollPosition'
import { useHeaderConfig } from './HeaderProvider'

type Props = {
  hideNavigation: boolean
  highlightCartItems: boolean
}

const MobileHeader = ({ hideNavigation, highlightCartItems }: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isShowHeader, setIsShowHeader] = useState(true)
  const { setShow: setContextShow } = useHeaderShow()

  const [isOpenTrucksMenu, setIsOpenTrucksMenu] = useState(false)
  const [isOpenCustomersMenu, setIsOpenCustomersMenu] = useState(false)
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)

  const { user, LocalLink } = useHeaderConfig()

  const subMenuIsOpen =
    isOpenProfileMenu || isOpenCustomersMenu || isOpenTrucksMenu

  const hideHeader = !isShowHeader && !subMenuIsOpen && !isOpenMenu

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (document.body.scrollHeight <= window.innerHeight) {
        setIsShowHeader(true)
        setContextShow(true)
      } else {
        const isShow =
          (currPos.y >= prevPos.y || currPos.y >= 0) &&
          window.innerHeight < document.body.scrollHeight + prevPos.y

        if (isShow !== isShowHeader) {
          setIsShowHeader(isShow)
        }
        setContextShow(!hideHeader)
      }
    },
    [isShowHeader, hideHeader]
  )
  const closeMenu = () => {
    setIsOpenMenu(false)
    setIsOpenTrucksMenu(false)
    setIsOpenCustomersMenu(false)
    setIsOpenProfileMenu(false)
  }
  return (
    <>
      <MainMobileMenu
        closeMenu={closeMenu}
        hideHeader={hideHeader}
        hideNavigation={hideNavigation}
        highlightCartItems={highlightCartItems}
        isOpenMenu={isOpenMenu}
        openMenu={() => setIsOpenMenu(true)}
      />

      {isOpenCustomersMenu && (
        <MobileMenu
          isOpen={isOpenCustomersMenu}
          onClose={() => setIsOpenCustomersMenu(false)}
        >
          <SubMenuItemsCustomers />
        </MobileMenu>
      )}

      {isOpenTrucksMenu && (
        <MobileMenu
          isOpen={isOpenTrucksMenu}
          onClose={() => setIsOpenTrucksMenu(false)}
        >
          <SubMenuItemsTrucks />
        </MobileMenu>
      )}

      {isOpenProfileMenu && (
        <MobileMenu
          isOpen={isOpenProfileMenu}
          onClose={() => setIsOpenProfileMenu(false)}
        >
          <>
            {user && user.amountCredit.cents > 0 && (
              <p className="text-orange-500 italic font-bold p-2 -mt-4">
                You have {user.amountCredit.formatted} credits
              </p>
            )}
            <LocalLink href="/profile">
              <SubMenuItem
                href="/profile"
                Icon={UserIcon}
                title="Account Information"
              />
            </LocalLink>
            <LocalLink href="/profile/orders">
              <SubMenuItem
                href="/profile/orders"
                Icon={OrdersIcon}
                title="My Orders"
              />
            </LocalLink>
            <LocalLink href="/profile/caterings">
              <SubMenuItem
                href="/profile/caterings"
                Icon={OrdersIcon}
                title="My Caterings"
              />
            </LocalLink>
            <LocalLink href="/profile/subscriptions">
              <SubMenuItem
                href="/profile/subscriptions"
                Icon={OrdersIcon}
                title="My Subscriptions"
              />
            </LocalLink>
            <SubMenuItem
              href="/customers/logout"
              Icon={LogoutIcon}
              title="Logout"
            />
          </>
        </MobileMenu>
      )}

      {!subMenuIsOpen && (
        <MobileMenu isOpen={isOpenMenu}>
          <>
            {!hideNavigation && (
              <>
                <SubMenuItem
                  href="/food-trucks-map"
                  Icon={MapIcon}
                  title="Food trucks map"
                />
                <SubMenuItem
                  Icon={ArrowCircleRightIcon}
                  onClick={() => setIsOpenCustomersMenu(true)}
                  title="For customers"
                  showChevron
                />

                <SubMenuItem
                  Icon={TruckIcon}
                  onClick={() => setIsOpenTrucksMenu(true)}
                  title="For truck owners"
                  showChevron
                />

                <SubMenuItem href="/faq" Icon={QuestionIcon} title="FAQ" />
                <SubMenuItem
                  href="/food-trucks-search"
                  Icon={SearchIcon}
                  title="Search"
                />
              </>
            )}

            {user ? (
              <SubMenuItem
                Icon={UserIcon}
                onClick={() => setIsOpenProfileMenu(true)}
                title="Profile"
                showChevron
              />
            ) : (
              <a
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-600 hover:bg-orange-700"
                href="/customers/login"
              >
                Login
              </a>
            )}
          </>
        </MobileMenu>
      )}
    </>
  )
}

export default MobileHeader
