import React from 'react'
import HowToBookFoodTrucksForYourOffice from 'components/pages/FaqBasePage/how-to-book-food-trucks-for-your-office'
import HowDoesBestFoodTrucksWork from 'components/pages/FaqBasePage/how-does-best-food-trucks-work'
import WhichFoodTrucksYouWorkWith from 'components/pages/FaqBasePage/which-food-trucks-you-work-with'
import IsThereAnyWorkOnMyPart from 'components/pages/FaqBasePage/is-there-any-work-on-my-part'
import HowMuchToHireFoodTruck from 'components/pages/FaqBasePage/how-much-to-hire-food-truck'
import HowDoesOrderOnlineWork from 'components/pages/FaqBasePage/how-does-order-online-work'
import WhatFoodTruckNeedsForOnlineOrders from 'components/pages/FaqBasePage/what-food-truck-needs-for-online-orders'
import WhatIfWeAlreadyHaveFoodTrucks from 'components/pages/FaqBasePage/what-if-we-already-have-food-trucks'
import WhatIfMyOfficeOrBusinessAlreadyHasCatering from 'components/pages/FaqBasePage/what-if-my-office-or-business-already-has-catering'
import DoYouHelpWithFoodTruckCatering from 'components/pages/FaqBasePage/do-you-help-with-food-truck-catering'

type FaqType = {
  title: string
  categoryCode: string
  slug: string
  hideForBoeing?: boolean
  component: React.FC
}

const faq: FaqType[] = [
  {
    title: 'How to book food trucks for offices?',
    categoryCode: 'catering',
    slug: 'how-do-i-book-food-trucks-for-my-office',
    hideForBoeing: true,
    component: HowToBookFoodTrucksForYourOffice
  },
  {
    title: 'How does food truck booking at Best Food Trucks work?',
    categoryCode: 'catering',
    slug: 'how-does-best-food-trucks-work',
    component: HowDoesBestFoodTrucksWork
  },
  {
    title: 'Which catering food trucks do you work with?',
    categoryCode: 'catering',
    slug: 'which-food-trucks-you-work-with',
    component: WhichFoodTrucksYouWorkWith
  },
  {
    title: 'Is there any work on my part?',
    categoryCode: 'catering',
    slug: 'is-there-any-work-on-my-part',
    component: IsThereAnyWorkOnMyPart
  },
  {
    title: 'How much do food trucks cost?',
    categoryCode: 'catering',
    slug: 'how-much-to-hire-food-truck',
    component: HowMuchToHireFoodTruck
  },
  {
    title: 'How does order online work?',
    categoryCode: 'orderahead',
    slug: 'how-does-order-online-work',
    component: HowDoesOrderOnlineWork
  },
  {
    title: 'What does a food truck need to receive online orders?',
    categoryCode: 'orderahead',
    slug: 'what-food-truck-needs-for-online-orders',
    component: WhatFoodTruckNeedsForOnlineOrders
  },
  {
    title: 'What if we already have food trucks?',
    categoryCode: 'catering',
    slug: 'what-if-we-already-have-food-trucks',
    component: WhatIfWeAlreadyHaveFoodTrucks
  },
  {
    title:
      'Why should I switch from my current catering service to food truck catering?',
    categoryCode: 'catering',
    slug: 'what-if-my-office-or-business-already-has-catering',
    hideForBoeing: true,
    component: WhatIfMyOfficeOrBusinessAlreadyHasCatering
  },
  {
    title: 'Do you help with food truck office catering?',
    categoryCode: 'catering',
    slug: 'do-you-help-with-food-truck-catering',
    hideForBoeing: true,
    component: DoYouHelpWithFoodTruckCatering
  }
]

export default faq
