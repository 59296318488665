import React, { ElementType } from 'react'
import cn from 'classnames'

type MenuItemProps = {
  title?: string
  href: string
  Icon?: ElementType
  transparentHeader?: boolean
  titleClassName?: string
  onClick?: React.MouseEventHandler
}

const MenuItem = React.forwardRef<HTMLAnchorElement, MenuItemProps>(
  (
    {
      title,
      href,
      Icon,
      transparentHeader,
      titleClassName,
      onClick
    }: MenuItemProps,
    ref
  ) => (
    <a
      ref={ref}
      className={cn(
        'group text-base font-medium flex items-center cursor-pointer lg:ml-10 transition duration-50 ease-in-out',
        {
          'hover:text-gray-50': transparentHeader,
          'text-gray-700 hover:text-gray-700': !transparentHeader
        }
      )}
      href={href}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          className={cn('mr-2 md:h-4 md:w-4 h-5 w-5', {
            'group-hover:text-gray-50': transparentHeader,
            'text-gray-700 group-hover:text-gray-500': !transparentHeader
          })}
        />
      )}
      {title && <span className={cn(titleClassName)}>{title}</span>}
    </a>
  )
)

MenuItem.displayName = 'MenuItem'

export default MenuItem
