import React, { useContext, useState } from 'react'

type HeaderShowContextValues = {
  show: boolean
  headerExists: boolean
  setShow: (value: boolean) => void
}

const HeaderShowContext = React.createContext<HeaderShowContextValues>({
  setShow: () => {},
  show: true,
  headerExists: true
})

export const useHeaderShow = (): HeaderShowContextValues => {
  return useContext(HeaderShowContext)
}

export const HeaderShowProvider = ({
  children,
  headerExists = true
}: {
  headerExists?: boolean
  children: React.ReactElement
}) => {
  const [show, setShow] = useState<boolean>(headerExists)
  return (
    <HeaderShowContext.Provider value={{ show, setShow, headerExists }}>
      {children}
    </HeaderShowContext.Provider>
  )
}
