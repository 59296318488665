import React from 'react'
import { useHeaderConfig } from 'components/Header/HeaderProvider'
import {
  ArrowCircleRightIcon,
  DeviceMobileIcon,
  PlusCircleIcon,
  TruckIcon
} from '@heroicons/react/outline'

import SubMenuItem from './SubMenuItem'

const SubMenuItemsCustomers = () => {
  const { LocalLink } = useHeaderConfig()

  return (
    <>
      <LocalLink href="/food-trucks-near-me">
        <SubMenuItem
          description="Browse trucks near you and order online"
          href="/food-trucks-near-me"
          Icon={ArrowCircleRightIcon}
          title="Order now from trucks"
        />
      </LocalLink>

      <SubMenuItem
        description={
          <div>
            <a
              className="p-4 text-orange-500 hover:text-orange-600 underline"
              href="https://apps.apple.com/us/app/best-food-trucks/id1513823045"
              rel="noopener noreferrer"
              target="_blank"
            >
              iOS
            </a>
            <a
              className="p-4 text-orange-500 hover:text-orange-600 underline"
              href="https://play.google.com/store/apps/details?id=com.bftcustomers&hl=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              Android
            </a>
          </div>
        }
        Icon={DeviceMobileIcon}
        title="Download the App"
      />

      <SubMenuItem
        description="Let us come to you. Set up a regular food truck program or just a one time event"
        href="/hire-food-truck"
        Icon={TruckIcon}
        title="Hire a food truck"
      />

      <SubMenuItem
        description="Keeping customers safe"
        href="/covid"
        Icon={PlusCircleIcon}
        title="COVID-19"
      />
    </>
  )
}
export default SubMenuItemsCustomers
