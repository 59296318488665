import { Card, CardActions, CardContent, CardImage } from 'components/ui/Card'
import Image from 'components/Image'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Button from 'components/ui/Button'
import { StaticImageData } from 'next/image'

type Props = {
  name: string
  image: string | StaticImageData
  address: string | null
  locationsThisDayCount?: number
  locationsThisWeekCount?: number
  lotPath?: string | null
}

const LotCard = ({
  name,
  image,
  address,
  locationsThisDayCount,
  locationsThisWeekCount,
  lotPath
}: Props) => {
  const router = useRouter()
  const hideMarketing =
    router.asPath.includes('boeing') || router.asPath.includes('homedepot')
  const children = (
    <Card>
      <>
        <CardImage>
          <Image
            alt=""
            className="object-cover object-center"
            layout="fill"
            sizes="25vw"
            src={image}
          />
        </CardImage>

        <CardContent>
          <>
            <p className="text-gray-900 text-lg font-medium">{name}</p>
            <div className="mt-2 text-sm text-gray-800 flex-1">
              {address && !hideMarketing && (
                <div className="gap-x-1 rounded-xl bg-gray-100 flex max-w-max px-1.5 py-0.5">
                  <LocationMarkerIcon className="w-5 h-5 text-gray-400" />
                  <span className="w-11/12">{address}</span>
                </div>
              )}

              <div className="flex md:flex-row flex-col md:mt-2 mt-1 ml-1">
                {locationsThisDayCount && locationsThisDayCount > 0 ? (
                  <div className="flex mt-1 items-center bg-orange-200 rounded-lg px-1.5 py-0.5 text-orange-800 font-semibold max-w-max">
                    <div className="flex justify-center items-center mr-1 font-bold rounded-full">
                      {locationsThisDayCount}
                    </div>
                    <p>trucks today</p>
                  </div>
                ) : null}

                {locationsThisWeekCount &&
                locationsThisWeekCount > 0 &&
                locationsThisDayCount &&
                locationsThisDayCount > 0 ? (
                  <div className="border-l border-solid border-gray-300 mx-2 mt-1 md:block hidden" />
                ) : null}

                {locationsThisWeekCount && locationsThisWeekCount > 0 ? (
                  <div className="flex mt-1 items-center">
                    <div className="flex justify-center items-center mr-1 font-bold text-orange-600 rounded-full">
                      {locationsThisWeekCount}
                    </div>
                    <p>trucks this week</p>
                  </div>
                ) : null}
              </div>

              <div />
            </div>
            {lotPath && (
              <CardActions>
                <Button
                  color="secondary"
                  onClick={() => {}}
                  size="small"
                  variant="outline"
                >
                  View Trucks Here
                </Button>
              </CardActions>
            )}
          </>
        </CardContent>
      </>
    </Card>
  )
  if (!lotPath) return children
  return (
    <Link href={lotPath} prefetch={false}>
      {children}
    </Link>
  )
}

export default LotCard
