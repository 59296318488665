const pathFromUrl = (url: string) => {
  if (!url.includes('http://') && !url.includes('https://')) {
    // eslint-disable-next-line no-console
    console.warn(`"${url}" is not an absolute URL`)
    return url
  }
  const parsed = new URL(url)
  return `${parsed.pathname}${parsed.search}`
}

export default pathFromUrl
