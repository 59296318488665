import { Popover, Transition } from '@headlessui/react'
import React from 'react'
import cn from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

type MenuTransitionProps = {
  title: string
  children: React.ReactElement
  transparentHeader: boolean
}
const MenuTransition = ({
  title,
  children,
  transparentHeader
}: MenuTransitionProps) => (
  <div className="relative inline-block text-left ml-10">
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={cn(
              'group rounded-md inline-flex text-base font-medium outline-none-mouse transition duration-50 ease-in-out items-center',
              {
                'hover:text-gray-50': transparentHeader,
                'text-gray-700 hover:text-gray-900': !transparentHeader
              }
            )}
          >
            <span>{title}</span>
            <ChevronDownIcon
              className={cn('ml-2 mt-0.5 h-5 w-5', {
                'group-hover:text-gray-50': transparentHeader,
                'text-gray-700 group-hover:text-gray-900': !transparentHeader
              })}
            />
          </Popover.Button>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={open}
          >
            <Popover.Panel
              className="absolute z-10 left-1/2 -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
              static
            >
              <div className="absolute mt-3 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 z-20">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="z-20 relative flex flex-col bg-white p-8">
                    {children}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  </div>
)

export default MenuTransition
