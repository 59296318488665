import React, { ReactElement } from 'react'
import { Transition } from '@headlessui/react'
import cn from 'classnames'

import BackMenuButton from './BackMenuButton'

const MobileMenu = ({
  isOpen,
  onClose,
  children
}: {
  isOpen: boolean
  onClose?: () => void
  children: ReactElement
}) => (
  <Transition
    enter="transition-opacity duration-75"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    show={isOpen}
  >
    <div
      className={cn(
        'fixed mt-16 inset-x-0 shadow-lg ring-2 ring-black ring-opacity-5 bg-white pt-5 pb-6 px-5 z-50',
        {
          'top-0': !onClose
        }
      )}
    >
      <nav className="flex flex-col space-y-4">
        {onClose && <BackMenuButton onClick={onClose} />}
        {children}
      </nav>
    </div>
  </Transition>
)

export default MobileMenu
