/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { ArrowLeftIcon } from '@heroicons/react/solid'

const BackMenuButton = ({ onClick }: { onClick: () => void }) => (
  <div
    className="-mt-5 -mx-5 p-3 flex items-center bg-gray-200 cursor-pointer"
    onClick={onClick}
  >
    <ArrowLeftIcon className="pt-0.5 w-6 h-6 text-gray-400 hover:text-gray-500" />
    <div className="ml-4">
      <p className="text-base font-medium text-gray-900">Back</p>
    </div>
  </div>
)

export default BackMenuButton
