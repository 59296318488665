import React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { ShoppingCartIcon } from '@heroicons/react/outline'

import { useHeaderConfig } from './HeaderProvider'

const CartComponent = ({
  highlightCartItems
}: {
  highlightCartItems: boolean
}) => {
  const { cartItems } = useHeaderConfig()

  return (
    <Link href="/cart" prefetch={cartItems > 0}>
      <div aria-label="Cart" className="relative ml-2">
        <ShoppingCartIcon className="h-7 w-7 text-orange-600" />
        {cartItems > 0 && (
          <span className="flex h-5 w-5 absolute -top-3 -right-2">
            <span
              className={cn(
                'absolute bg-red-500 h-5 w-5 rounded-full text-white flex justify-center items-center text-sm opacity-75',
                {
                  'animate-ping': highlightCartItems
                }
              )}
            />
            <span className="relative bg-red-500 h-5 w-5 rounded-full text-white flex justify-center items-center font-semibold absolute text-sm">
              {cartItems}
            </span>
          </span>
        )}
      </div>
    </Link>
  )
}
export default CartComponent
