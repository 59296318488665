import React from 'react'
import cn from 'classnames'

export const CardImage = ({ children }: { children: React.ReactElement }) => (
  <div className="w-1/3 h-full rounded-l-md overflow-hidden relative">
    {children}
  </div>
)

export const CardActions = ({ children }: { children: React.ReactElement }) => (
  <div className="mt-4 justify-self-end">{children}</div>
)

export const CardContent = ({ children }: { children: React.ReactElement }) => (
  <div className="w-2/3 p-4 flex flex-col">{children}</div>
)

export const Card = ({
  children,
  noMargin = false
}: {
  children: React.ReactElement
  noMargin?: boolean
}) => (
  <div
    className={cn('h-full', {
      'px-4 md:py-4 py-2': !noMargin
    })}
  >
    <div className="h-full rounded-md bg-white flex border-gray-200 border border-solid shadow">
      {children}
    </div>
  </div>
)
