import React from 'react'
import { StarIcon as OutlinedStar } from '@heroicons/react/outline'

type Props = {
  stars: number
  scale: number
}

const STARS = [1, 2, 3, 4, 5]

const Star = ({ selected, scale }: { selected: boolean; scale: number }) => {
  return (
    <OutlinedStar
      className="text-yellow-300"
      fill={selected ? 'rgb(252, 211, 77)' : 'none'}
      style={{ width: 10 * scale, height: 10 * scale }}
    />
  )
}

export default function StarsRating({ stars, scale }: Props) {
  return (
    <div aria-label={`${stars} stars`} className="flex flex-row">
      {STARS.map((star) => {
        const selected = stars ? star <= stars : false
        return <Star key={star} scale={scale} selected={selected} />
      })}
    </div>
  )
}
