/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ElementType } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'

type SubMenuItemInnerProps = {
  title: string
  description?: string | JSX.Element
  Icon?: ElementType
  showChevron?: boolean
}

type SubMenuItemProps = SubMenuItemInnerProps & {
  href?: string
  onClick?: () => void
}

const SubMenuItemInner = ({
  title,
  description,
  Icon,
  showChevron
}: SubMenuItemInnerProps) => (
  <div className="flex items-center rounded-lg">
    {Icon && <Icon className="flex-shrink-0 h-6 w-6 text-orange-500 mr-4" />}
    <div className="w-full">
      <div className="flex items-end justify-between w-full">
        <p className="text-base font-medium text-gray-900">{title}</p>
        {showChevron && (
          <ChevronRightIcon className="text-gray-400 ml-2 h-6 w-6" />
        )}
      </div>
      {description && (
        <div className="mt-1 text-sm text-gray-500">{description}</div>
      )}
    </div>
  </div>
)

const SubMenuItem = React.forwardRef<HTMLAnchorElement, SubMenuItemProps>(
  ({ title, description, Icon, href, onClick, showChevron }, ref) => (
    <div className="py-2 hover:bg-gray-50 focus:bg-orange-100 border-b-2 border-solid border-gray-50 md:border-0">
      {href ? (
        <a
          ref={ref}
          className="active:bg-orange-100"
          href={href}
          onClick={onClick}
        >
          <SubMenuItemInner
            description={description}
            Icon={Icon}
            showChevron={showChevron}
            title={title}
          />
        </a>
      ) : (
        <div onClick={onClick}>
          <SubMenuItemInner
            description={description}
            Icon={Icon}
            showChevron={showChevron}
            title={title}
          />
        </div>
      )}
    </div>
  )
)
SubMenuItem.displayName = 'SubMenuItem'

export default SubMenuItem
