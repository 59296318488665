import { Card, CardActions, CardContent, CardImage } from 'components/ui/Card'
import Image from 'components/Image'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import ButtonLink from 'components/ui/ButtonLink'
import React from 'react'

type Props = {
  name: string
  image: string
  fullAddress: string
  path: string
}

const MarketCard = ({ name, image, fullAddress, path }: Props) => {
  return (
    <Card>
      <>
        <CardImage>
          <Image
            className="object-cover object-center"
            layout="fill"
            sizes="25vw"
            src={image}
          />
        </CardImage>

        <CardContent>
          <>
            <p className="text-gray-900">{name}</p>
            <div className="mt-2 text-sm text-gray-800">
              {/* <div className="flex"> */}
              {/*  <TruckIcon className="w-5 h-5 text-gray-400 mr-1 w-1/12" /> */}
              {/*  <p className="w-11/12">{foodTypes}</p> */}
              {/* </div> */}
              <div className="flex">
                <LocationMarkerIcon className="w-5 h-5 text-gray-400 mr-1 w-1/12" />
                <p className="w-11/12">{fullAddress}</p>
              </div>
              <div />
            </div>
            <CardActions>
              <ButtonLink
                color="secondary"
                href={path}
                prefetch={false}
                size="small"
                variant="outline"
              >
                View Trucks Here
              </ButtonLink>
            </CardActions>
          </>
        </CardContent>
      </>
    </Card>
  )
}
export default MarketCard
