import Image from 'components/Image'
import image from 'images/faq/BFT-TruckAndFood.jpg'
import React from 'react'
import { Link } from 'components/pages/FaqPage'
import ButtonLink from 'components/ui/ButtonLink'

const HowMuchToHireFoodTruck = () => (
  <div className="prose prose-md max-w-none">
    <h1 itemProp="name">How Much to Hire a Food Truck?</h1>
    <div>
      <Image alt="BFT Events" className="shadow rounded-lg" src={image} />
    </div>

    <div
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
      itemScope
    >
      <div className="text-justify" itemProp="text">
        <p>
          Signing up and integrating with Best Food Trucks (BFT) is FREE! If
          you’re a property manager, employer, commercial real estate company,
          apartment building, leasing office, or anyone else who wants to{' '}
          <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
            book food trucks
          </Link>{' '}
          for a regular stop or catering/events, there may be a food truck
          minimum guarantee required. Best Food Trucks does not charge any
          management fee.
        </p>
        <h3>What Services Does BFT Provide for Free?</h3>
        <p>
          That means we’ll take care of all the logistics of booking food
          trucks, negotiating minimums, setting up a schedule of trucks that
          constantly shift cuisines, and providing an order-ahead technology
          that allows people to order ahead from the food trucks via their
          desktop or phone web browser. And all of this costs a grand total of
          zero to Best Food Trucks.
        </p>
        <h3>How Does BFT Make Money?</h3>
        <p>
          So how do we make our money? We work with the food trucks to provide
          them access and vending locations, and maximize business for them for
          a small convenience fee—approximately one-third of our competitors. If
          we don’t send them wonderful customers like you, we don’t make money
          ourselves. Either way, food truck catering costs you nothing.
        </p>
        <h3>Why Should You Sign Up with BFT?</h3>
        <p>
          In short, if having a professional chef in a mobile kitchen parked
          right outside, which keeps your tenants, residents, and/or employees
          happy, is completely free and makes you look great, what’s stopping
          you from signing up? :)
        </p>
        <h3>Contact Us for More Information About Food Truck Catering</h3>
        <p>
          Contact us for more information about the cost of food truck catering
          at events. Or, give us a call if you are interested in{' '}
          <Link href="https://www.bestfoodtrucks.com/blog/how-much-does-catering-cost">
            booking food trucks for your upcoming event
          </Link>
          .
        </p>
        <h3>Are There Any Hidden Costs with BFT?</h3>
        <p>
          At Best Food Trucks, there are no hidden fees. Everything, from truck
          scheduling to payment processing, is managed by BFT without any
          surprise charges. Our service is structured to benefit property
          managers, employers, and event organizers who want hassle-free
          catering without worrying about unexpected costs.
        </p>
        <p>
          BFT ensures that the food truck catering cost is completely
          transparent, meaning the only money you spend is on the food your team
          or guests order. Whether it’s for daily lunches, corporate events, or
          special occasions, BFT handles the logistics at no cost to you.
        </p>

        <h3>What Makes BFT More Affordable Than Competitors?</h3>
        <p>
          Many companies offering food truck services charge event organizers or
          property managers a hefty fee for access to food trucks, but BFT
          doesn’t. Our convenience fee is only charged to the food trucks, and
          it’s one-third less than what competitors typically charge. This means
          food trucks are happy to partner with us, and you get access to a
          broader range of vendors without spending a dime. In short, it makes
          for affordable catering services.
        </p>
        <h3>Why Choose BFT for Event Catering?</h3>
        <p>
          Best Food Trucks not only offers{' '}
          <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
            free food truck booking
          </Link>{' '}
          and services, but also provides premium features like order-ahead
          technology and regular feedback collection to improve service. This
          allows us to fine-tune which trucks work best for your events,
          offering fresh and varied menu options that suit every taste and
          dietary need.
        </p>
        <p>
          In addition, BFT helps property managers and event planners by
          curating a rotation of trucks to keep food choices exciting. You don’t
          have to worry about dealing with multiple vendors or repeating the
          same food every week. We make sure every experience is unique, fresh,
          and perfectly tailored to your event or location.
        </p>

        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    </div>
  </div>
)

export default HowMuchToHireFoodTruck
