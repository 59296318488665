import {
  HeaderContext,
  HeaderContextValues,
  LocalLinkType
} from 'components/Header/HeaderProvider'
import React from 'react'
import useCart from 'queries/useCart'
import useUser from 'queries/useUser'
import Link from 'next/link'

import logoUrl from '../../images/bft-logo-black-truck-only-small.inline.png'

type Props = {
  staticPage?: boolean
  children: React.ReactNode
}

const NextLink: LocalLinkType = ({ href, children }) => (
  <Link href={href} prefetch={false} legacyBehavior>
    {children}
  </Link>
)

export default function NextHeaderProvider({ children, staticPage }: Props) {
  let cart
  let user
  if (staticPage && typeof window === 'undefined') {
    cart = null
    user = null
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks, prefer-destructuring
    cart = useCart().cart
    // eslint-disable-next-line react-hooks/rules-of-hooks, prefer-destructuring
    user = useUser().user
  }

  const config: HeaderContextValues = {
    truckDashboardHeaderUrl: (url) =>
      `${process.env.NEXT_PUBLIC_TRUCK_DASHBOARD_URL}${url}`,
    logoUrl,
    cartItems: cart?.items?.length || 0,
    user,
    LocalLink: NextLink
  }

  return (
    <HeaderContext.Provider value={config}>{children}</HeaderContext.Provider>
  )
}
