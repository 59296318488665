import { ApolloClient, gql } from '@apollo/client'
import {
  currentCustomerQuery,
  currentCustomerQuery_currentCustomer as currentCustomerQueryCurrentCustomer
} from 'queries/types/currentCustomerQuery'

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragmentOld on Customer {
    id
    name
    firstName
    lastName
    email
    phone
    completeProfile
    anonymous
    authenticationToken
    referralCode
    tawkHash
    amountCredit {
      cents
      formatted
    }
  }
`

export const QUERY = gql`
  ${CURRENT_USER_FRAGMENT}
  query currentCustomerQuery {
    currentCustomer {
      ...CurrentUserFragmentOld
    }
  }
`

type Args = {
  client: ApolloClient<object>
  fetchPolicy?: 'cache-first' | 'network-only'
}

export default async function getCurrentUser({
  client,
  fetchPolicy = 'cache-first'
}: Args): Promise<currentCustomerQueryCurrentCustomer | null> {
  const result = await client.query<currentCustomerQuery>({
    query: QUERY,
    fetchPolicy
  })

  return result?.data?.currentCustomer
}
