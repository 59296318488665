import React from 'react'

import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'

type Props = {
  hideNavigation?: boolean
  transparentOnTop?: boolean
  highlightCartItems?: boolean
}

const Header = ({
  hideNavigation = false,
  transparentOnTop = false,
  highlightCartItems = true
}: Props) => {
  return (
    <>
      <MobileHeader
        hideNavigation={hideNavigation}
        highlightCartItems={highlightCartItems}
      />
      <DesktopHeader
        hideNavigation={hideNavigation}
        highlightCartItems={highlightCartItems}
        transparentOnTop={transparentOnTop}
      />
    </>
  )
}

export default Header
