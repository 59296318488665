import Header from 'components/Header'
import NextHeaderProvider from 'components/NextHeaderProvider'
import React from 'react'
import Footer from 'components/Footer'
import { HeaderShowProvider } from 'components/Header/HeaderShow'
import cn from 'classnames'

type Props = {
  children: React.ReactElement | React.ReactElement[]
  gray?: boolean
  dark?: boolean
  staticPage?: boolean
  transparentOnTop?: boolean
  hideFooter?: boolean
  hideNavigation?: boolean
  fullWidth?: boolean
  noWidth?: boolean
  highlightCartItems?: boolean
  meta?: React.ReactNode
}

const PageLayout = ({
  children,
  staticPage = false,
  gray,
  dark,
  hideFooter = false,
  hideNavigation = false,
  transparentOnTop,
  highlightCartItems,
  fullWidth = false,
  noWidth = false,
  meta
}: Props) => {
  return (
    <HeaderShowProvider headerExists={!hideNavigation}>
      <div
        className={cn('min-h-screen h-full flex flex-col justify-between', {
          'bg-gray-50': gray,
          'bg-background-dark': dark
        })}
      >
        {meta}
        <div className="min-w-full min-h-96">
          <NextHeaderProvider staticPage={staticPage}>
            <Header
              hideNavigation={hideNavigation}
              highlightCartItems={highlightCartItems}
              transparentOnTop={transparentOnTop}
            />
          </NextHeaderProvider>
          <div
            className={cn({
              'flex justify-center': !noWidth,
              'md:pt-20 pt-16': !transparentOnTop,
              'md:max-w-screen-md lg:max-w-screen-xl max-w-screen-sm mx-auto': !fullWidth,
              '': fullWidth
            })}
          >
            {children}
          </div>
        </div>
        <Footer hideFooter={hideFooter} />
      </div>
    </HeaderShowProvider>
  )
}

export default PageLayout
