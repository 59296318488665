import { LotHit, MarketHit, TruckHit } from 'lib/search/useSearch'
import TruckCard from 'components/TruckCard'
import MarketCard from 'components/MarketCard'
import {
  TruckIcon,
  LocationMarkerIcon,
  GlobeIcon
} from '@heroicons/react/outline'
import LotCard from 'components/LotCard'

type Props = {
  lotHits: LotHit[]
  truckHits: TruckHit[]
  marketHits: MarketHit[]
  showEmptyResult: boolean
}
export default function SearchResults({
  lotHits,
  truckHits,
  marketHits,
  showEmptyResult
}: Props) {
  if (showEmptyResult)
    return (
      <p className="my-4 flex items-center justify-center italic w-full text-gray-400 text-xl h-48">
        No matching trucks or locations found
      </p>
    )
  if (marketHits.length === 0 && truckHits.length === 0 && lotHits.length === 0)
    return (
      <div className="my-10 bg-gray-50">
        <h2 className="font-semibold text-2xl text-gray-800 text-center">
          No results found
        </h2>
        <p className="mt-2 leading-6 text-gray-500 italic">
          No matching trucks or locations found. Try searching for something
          else.
        </p>
      </div>
    )
  return (
    <div className="w-full">
      {marketHits.length > 0 && (
        <div className="mb-10">
          <div className="flex flex-col items-center mt-6">
            <div className="p-3 rounded-full bg-gray-700">
              <GlobeIcon className="md:w-7 md:h-7 text-gray-50 w-6 h-6" />
            </div>
            <h2 className="text-2xl font-bold leading-5 text-gray-700 my-4">
              Markets
            </h2>
          </div>
          <div className="flex flex-wrap box-border">
            {marketHits.map((market) => (
              <div key={market.objectID} className="md:w-1/2 w-full">
                <MarketCard
                  fullAddress={market.full_address}
                  image={market.image_medium}
                  name={market.name}
                  path={market.path}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {truckHits.length > 0 && (
        <div className="mb-10">
          <div className="flex flex-col items-center mt-6">
            <div className="p-3 rounded-full bg-gray-700">
              <TruckIcon className="md:w-7 md:h-7 text-gray-50 w-6 h-6" />
            </div>
            <h2 className="text-2xl font-bold leading-5 text-gray-700 my-4">
              Trucks
            </h2>
          </div>
          <div className="flex flex-wrap box-border">
            {truckHits.map((truck) => (
              <div key={truck.objectID} className="md:w-1/2 w-full">
                <TruckCard
                  foodTypes={truck.food_types.map((f) => ({
                    name: f
                  }))}
                  image={truck.image_medium}
                  market={truck.market}
                  name={truck.name}
                  truckPath={truck.path}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {lotHits.length > 0 && (
        <div className="">
          <div className="flex flex-col items-center mt-6">
            <div className="p-3 rounded-full bg-gray-700">
              <LocationMarkerIcon className="md:w-7 md:h-7 text-gray-50 w-6 h-6" />
            </div>
            <h2 className="text-2xl font-bold leading-5 text-gray-700 my-4">
              Truck Locations
            </h2>
          </div>
          <div className="flex flex-wrap box-border">
            {lotHits.map((lot) => (
              <div key={lot.objectID} className="md:w-1/2 w-full">
                <LotCard
                  address={lot.full_address}
                  image={lot.image_medium}
                  lotPath={lot.path}
                  name={lot.name}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
