import Image from 'components/Image'
import image from 'images/faq/BFT-CateringOrder.jpg'
import React from 'react'
import ButtonLink from 'components/ui/ButtonLink'

const HowDoesOrderOnlineWork = () => (
  <div className="prose prose-md max-w-none">
    <h1 itemProp="name">How Does Food Truck Online Ordering Work?</h1>
    <div className="max-w-lg">
      <Image alt="Office catering" className="shadow rounded-lg" src={image} />
    </div>
    <div
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
      itemScope
    >
      <div className="text-justify" itemProp="text">
        <p>
          Once you’ve signed up with Best Food Trucks (BFT) to manage food truck
          catering for your event or lunch, we’ll do all the work to set up
          online ordering for you. Here’s how it typically works:
        </p>

        <h3>Food Truck Catering: Online Ordering Process</h3>
        <ol>
          <li>
            First, we’ll give you a unique website URL like
            bestfoodtrucks.com/(yourname) to make it easy for you to bookmark so
            you always know where to go when ordering.
          </li>
          <li>
            As we book trucks, they will show up on your customized page. If
            it’s a regular stop, you can see the upcoming week along with the
            full schedule of trucks coming up. If it’s a one-time event, you’ll
            just see the available trucks.
          </li>
          <li>
            If there’s more than one truck, we’ll show you a couple of the most
            popular items each truck has first, then the full menu so it’s
            easier for you to choose which truck you’d like to eat from.
          </li>
          <li>
            When it’s the day of the food truck, we’ll email you the moment the
            truck goes live. Then, you can view the photos, choose what you want
            to order, and checkout. Typically we’ll ask for your email (so we
            can send you a receipt), mobile phone number (so we can send you
            order updates via text message), and a name (so the truck knows who
            you are on top of your order number).
          </li>
          <li>
            You’ll see the estimated time before you order so you have an idea
            as to how long it will take to be ready.
          </li>
          <li>
            We’ll take payment so you won’t have to pay at the truck. No more
            having to fumble for your card or wait for a credit card machine. If
            you want, we’ll keep your card on file so you can order quickly in
            the future. Once you get your account setup, it literally takes 30
            seconds for orders in the future, so it’s incredibly easy!
          </li>
          <li>
            That’s it! Sit back, relax, and we’ll text you when your food is
            ready. If for some reason the order takes longer than 15 minutes,
            we’ll give you regular text message updates so you don’t have to
            wonder how long your order will be ready.
          </li>
          <li>
            When you’re done, we’ll send a followup text asking if you want to
            rate your meal from 1 (worst) to 5 (best). This is totally optional,
            but it helps us learn what trucks you want more of, and which ones
            you don’t want back. Your happiness is incredibly important to us,
            so we want to make sure we’re sending the right trucks with the
            right food.
          </li>
        </ol>

        <h3>How BFT Creates a Seamless Ordering Experience</h3>
        <p>
          At Best Food Trucks, we’ve designed the online ordering system to be
          as user-friendly and seamless as possible. From the moment you sign
          up, everything is automated for your convenience. We handle everything
          — from setting up your unique URL to integrating payment options—so
          your food truck experience is hassle-free. Whether you&apos;re hosting
          a one-time event or organizing weekly food truck visits, you can rely
          on us for smooth operations and fast, secure ordering.
        </p>
        <p>
          We’ve streamlined the checkout process to eliminate any friction,
          ensuring that your team or guests can focus on enjoying their meal.
          You’ll receive real-time updates on your order status, eliminating the
          guesswork about when your food will be ready.
        </p>
        <h3>The Benefits of Order-Ahead Technology</h3>
        <p>
          One of the standout features of BFT&apos;s order-ahead technology is
          how it simplifies meal planning for busy office workers, event
          attendees, and residents. By allowing users to browse menus in
          advance, make selections, and pay online, the entire process is
          expedited. This means no standing in line, no awkward waits at the
          truck, and no surprises when it&apos;s time to pay. The system ensures
          you’re informed every step of the way, from ordering to pickup
          notifications.
        </p>
        <h3>How We Use Your Feedback to Improve</h3>
        <p>
          Your feedback is invaluable to us. After each order, our optional
          rating system helps us gather real-time insights into which food
          trucks are performing well and which ones might need improvement. This
          allows us to fine-tune the selection of trucks for future bookings,
          ensuring only the highest-rated trucks return. We also use this data
          to rotate trucks and offer fresh options, so you’ll never be bored
          with the same selection.
        </p>
        <h3>Why BFT’s Online Ordering System is Perfect for Large Events</h3>
        <p>
          If you’re planning a large event like a music festival, a corporate
          gathering, or an apartment community event, BFT’s food truck online
          ordering system is the perfect solution. Attendees can order from
          their phones, reducing congestion at the trucks, and the system keeps
          everything moving efficiently. No more long lines or confusion at the
          truck—everything is handled through the app, so attendees can focus on
          enjoying the event while we manage the logistics.
        </p>
        <h3>Sign Up for Best Food Trucks</h3>
        <p>To sign up for Best Food Trucks (BFT), click the button below.</p>

        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    </div>
  </div>
)

export default HowDoesOrderOnlineWork
