import React from 'react'
import { HeaderUser, useHeaderConfig } from 'components/Header/HeaderProvider'

import SubMenuItem from './SubMenuItem'

type Props = {
  user: HeaderUser | null
}

const SubMenuItemsProfile = ({ user }: Props) => {
  const { LocalLink } = useHeaderConfig()

  return (
    <>
      {user && user.amountCredit.cents > 0 && (
        <p className="text-orange-500 italic font-bold p-2 -mt-4">
          You have {user.amountCredit.formatted} credits
        </p>
      )}
      <LocalLink href="/profile">
        <SubMenuItem href="/profile" title="Account Information" />
      </LocalLink>
      <LocalLink href="/profile/orders">
        <SubMenuItem href="/profile/orders" title="My Orders" />
      </LocalLink>
      <LocalLink href="/profile/caterings">
        <SubMenuItem href="/profile/caterings" title="My Caterings" />
      </LocalLink>
      <LocalLink href="/profile/subscriptions">
        <SubMenuItem href="/profile/subscriptions" title="My Subscriptions" />
      </LocalLink>
      <SubMenuItem href="/customers/logout" title="Logout" />
    </>
  )
}
export default SubMenuItemsProfile
