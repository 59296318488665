import React, { useContext } from 'react'

export type HeaderUser = {
  id: number
  amountCredit: { cents: number; formatted: string }
}

export type LocalLinkType = (args: {
  href: string
  children: React.ReactElement
}) => React.ReactElement

export type HeaderContextValues = {
  truckDashboardHeaderUrl: (url: string) => string
  logoUrl: string
  cartItems: number
  user: HeaderUser | null
  LocalLink: LocalLinkType
}

export const ExternalLink: LocalLinkType = ({ children }) => children

export const HeaderContext = React.createContext<HeaderContextValues>({
  truckDashboardHeaderUrl: () => '',
  logoUrl: '',
  cartItems: 0,
  user: null,
  LocalLink: ExternalLink
})

export const useHeaderConfig = (): HeaderContextValues => {
  return useContext(HeaderContext)
}
