import React from 'react'
import Head from 'next/head'
import SearchWidget from 'components/Search/SearchWidget'
import Image from 'components/Image'
import errorBackground from 'images/error-background.jpg'
import PageLayout from 'components/pages/PageLayout'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export type Props = {
  search?: string
}
const NotFoundPage = ({ search }: Props) => (
  <PageLayout dark={!search} gray={!!search} fullWidth staticPage>
    <>
      <Head>
        <title>Not found</title>
        <meta content="noindex" name="robots" />
      </Head>

      <div className="absolute top-22 left-0 right-0 w-full h-96 overflow-hidden">
        <Image
          className="object-cover object-center"
          layout="fill"
          quality={75}
          src={errorBackground}
          priority
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-96 bg-black opacity-50" />
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-96 flex justify-center items-center">
          <ExclamationCircleIcon className="text-orange-500 w-8 h-8 mr-2" />
          <p className="text-white font-extrabold leading-tight md:text-3xl text-xl text-center">
            This page could not be found
          </p>
        </div>
      </div>
      {search && (
        <div className="mt-96 w-full">
          <div className="pb-10 max-w-7xl w-full mx-auto">
            <SearchWidget
              header="What are you looking for?"
              initialQuery={search}
            />
          </div>
        </div>
      )}
    </>
  </PageLayout>
)

export default NotFoundPage
