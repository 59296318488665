import React from 'react'

const CloseMobileMenuButton = ({ onClick }: { onClick: () => void }) => (
  <button
    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 outline-none-mouse hover:bg-gray-50 focus:bg-orange-100"
    onClick={onClick}
    type="button"
  >
    <span className="sr-only">Close menu</span>
    <svg
      aria-hidden="true"
      className="h-6 w-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18L18 6M6 6l12 12"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </button>
)

export default CloseMobileMenuButton
