import React from 'react'
import {
  DeviceMobileIcon,
  LoginIcon,
  PlusCircleIcon,
  TruckIcon,
  CurrencyDollarIcon
} from '@heroicons/react/outline'

import { useHeaderConfig } from '../../HeaderProvider'

import SubMenuItem from './SubMenuItem'

const SubMenuItemsTrucks = () => {
  const { truckDashboardHeaderUrl } = useHeaderConfig()

  return (
    <>
      <SubMenuItem
        description="Learn how BFT can grow your business"
        href="/trucks"
        Icon={CurrencyDollarIcon}
        title="Benefits"
      />
      <SubMenuItem
        description={
          <p>
            <a
              className="p-4 text-orange-500 hover:text-orange-600 underline"
              href="https://itunes.apple.com/us/app/best-food-trucks-bft/id1354908972"
              rel="noopener noreferrer"
              target="_blank"
            >
              iOS
            </a>
            <a
              className="p-4 text-orange-500 hover:text-orange-600 underline"
              href="https://play.google.com/store/apps/details?id=com.bft"
              rel="noopener noreferrer"
              target="_blank"
            >
              Android
            </a>
          </p>
        }
        Icon={DeviceMobileIcon}
        title="Truck App"
      />
      <SubMenuItem
        description="Find offers from our partners"
        href={truckDashboardHeaderUrl('/marketplace')}
        Icon={TruckIcon}
        title="Truck marketplace"
      />
      <SubMenuItem
        description="Keeping customers safe"
        href="/covid"
        Icon={PlusCircleIcon}
        title="COVID-19"
      />
      <SubMenuItem
        description=""
        href={truckDashboardHeaderUrl('/login')}
        Icon={LoginIcon}
        title="Login / Sign up"
      />
    </>
  )
}
export default SubMenuItemsTrucks
