import { User } from 'queries/useUser'

type TawkUserParams = {
  hash: string
  userId: string
  name: string
  email: string
  phone: string
}

type TawkGlobal = {
  setAttributes: (
    params: TawkUserParams,
    callback: (error: unknown) => void
  ) => void
  login: (params: TawkUserParams, callback: (error: unknown) => void) => void
  logout: (callback: (error: unknown) => void) => void
  visitor: Partial<TawkUserParams>
}

type ChatLoginArgs = {
  user: User
}

const addCallback = (callback: () => void) => {
  if (typeof window === 'undefined') {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Tawk_API_Callbacks = (window as any).Tawk_API_Callbacks || []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { Tawk_API_Callbacks } = window as any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any)?.Tawk_Window?.app) {
    callback()
    return
  }

  Tawk_API_Callbacks.push(callback)
}

export function chatLogin({ user }: ChatLoginArgs) {
  if (typeof window === 'undefined') {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Tawk_API = (window as any).Tawk_API || {}

  addCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Tawk = (window as any).Tawk_API as TawkGlobal

    if (!user.tawkHash) return

    const params = {
      hash: user.tawkHash,
      userId: user.id.toString(),
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: user.phone ? `+1${user.phone}` : '',
      // set custom properties as well
      'user-phone': user.phone,
      'user-email': user.email
    }

    // buggy for some reason
    // Tawk.visitor = params
    Tawk.setAttributes(params, (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  })
}

export function chatLogout() {
  if (typeof window === 'undefined') {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Tawk_API = (window as any).Tawk_API || {}

  addCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Tawk = (window as any).Tawk_API as TawkGlobal

    if (!Tawk.visitor || Tawk.visitor === {}) return

    Tawk.visitor = {}
    Tawk.setAttributes(
      {
        hash: '',
        userId: '',
        email: '',
        name: '',
        phone: ''
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    )
  })
}
