import { Card, CardActions, CardContent, CardImage } from 'components/ui/Card'
import Image from 'components/Image'
import { LocationMarkerIcon, ArrowSmRightIcon } from '@heroicons/react/solid'
import ButtonLink from 'components/ui/ButtonLink'
import React from 'react'
import StarsRating from 'components/StarsRating'
import { trucksListQuery_market_popularTrucks_records_menus_foodTypes } from 'components/TrucksList/types/trucksListQuery'

type Props = {
  name: string
  image: string
  truckPath: string
  market?: string
  rating?: {
    averageRating: number
    ratingsCount: number
  } | null
  foodTypes?:
    | trucksListQuery_market_popularTrucks_records_menus_foodTypes[]
    | { name: string }[]
}

const TruckCard = ({
  name,
  image,
  truckPath,
  market,
  rating,
  foodTypes
}: Props) => {
  return (
    <Card>
      <>
        <CardImage>
          <Image
            className="object-cover object-center"
            layout="fill"
            sizes="25vw"
            src={image}
          />
        </CardImage>

        <CardContent>
          <>
            <div className="lg:flex justify-between gap-x-2 items-start">
              <p className="text-gray-900 text-lg font-semibold">{name}</p>
              {rating && (
                <div className="lg:mb-0 mt-1 mb-4">
                  <div className="flex items-center">
                    <StarsRating
                      scale={1.2}
                      stars={Math.round(rating.averageRating)}
                    />
                    <p className="text-sm font-semibold ml-1">
                      {Math.round(rating.averageRating * 10) / 10}
                    </p>
                  </div>
                  <p className="text-gray-500 text-xs ml-1 lg:text-right">
                    {rating.ratingsCount} reviews
                  </p>
                </div>
              )}
            </div>

            <div className="mt-2 flex flex-1">
              <div className="text-sm font-medium">
                {foodTypes && (
                  <div className="flex gap-y-1 gap-x-1.5 flex-wrap text-orange-900">
                    {foodTypes.map((f) => (
                      <p
                        key={f.name}
                        className="px-2 py-0.5 rounded-lg bg-orange-100"
                      >
                        {f.name}
                      </p>
                    ))}
                  </div>
                )}
                {market && (
                  <div className="flex mt-1">
                    <LocationMarkerIcon className="w-5 h-5 text-gray-400 mr-1" />
                    <p className="w-11/12">{market}</p>
                  </div>
                )}
                <div />
              </div>
            </div>
            <CardActions>
              <ButtonLink
                color="secondary"
                href={truckPath}
                prefetch={false}
                size="small"
                variant="outline"
              >
                Schedule & Menu <ArrowSmRightIcon className="w-4 h-4 ml-1" />
              </ButtonLink>
            </CardActions>
          </>
        </CardContent>
      </>
    </Card>
  )
}
export default TruckCard
